import { PermissionCheckFacility, PermissionCheckMixin, VIEW_PERMISSION, CONTAINER_FILLING_CONTAINERPRODUCT, CONTAINER_FILLING_MANUALLABEL, CONTAINER_FILLING_WEDGEMAINTAIN, CONTAINER_FILLING_REPORTS, CONTAINER_FILLING_SAMPLEPROCESSING, CONTAINER_FILLING_ADMINISTRATION, CONTAINER_FILLING_ITEM, CONTAINER_FILLING_SPECIFICATION, CONTAINER_FILLING_PRODUCTIONLINE, CONTAINER_FILLING_LABELFORMAT, CONTAINER_FILLING_PRINTER, CONTAINER_FILLING_STATUS, CONTAINER_FILLING_CONTAINTERTYPE, APP_NAME, AuthTypes } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "body" }, [_c("portal", { attrs: { "to": "facility-select" } }, [_c("div", { style: { width: "166px" } }, [_c("cf-select-input", { attrs: { "option-filter": _vm.filterFacilityOption, "data-source": _vm.facilityOptions, "source": "code", "default-active-first-option": "", "source-label": _vm.getFacilityLabel, "value": _vm.facilityValue }, on: { "change": _vm.onFacilityChange } })], 1)]), _c("portal", { attrs: { "to": "sider-items" } }, [_c("div", { staticClass: "kh-application p-4" }, [_c("p", { staticClass: "m-0" }, [_vm._v("CONTAINER FILLING")]), _c("h5", { staticClass: "mb-0" }, [_vm._v("Container Filling")])]), _c("a-menu", { attrs: { "open-keys": _vm.openKeys, "mode": "inline", "theme": "light", "inline-collapsed": _vm.collapsed, "selected-keys": _vm.selectedKeys } }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.child.length === 0 && (item.key === "home" || _vm.$can(_vm.permissions.view, item.page)) ? [_c("a-menu-item", { key: item.key, on: { "click": function($event) {
      return _vm.clickItem($event);
    } } }, [_c("router-link", { attrs: { "to": item.path } }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1)] : _vm._e(), item.child.length > 0 ? [_c("a-sub-menu", { key: item.key, on: { "titleClick": function($event) {
      return _vm.clickParentItem($event);
    } } }, [_c("span", { attrs: { "slot": "title" }, slot: "title" }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.child, function(itemChild) {
      return [!itemChild.hide && _vm.$can(_vm.permissions.view, itemChild.page) ? _c("a-menu-item", { key: itemChild.key, on: { "click": function($event) {
        return _vm.clickItem($event);
      } } }, [_c("router-link", { attrs: { "to": itemChild.path } }, [_vm._v(_vm._s(itemChild.title))])], 1) : _vm._e()];
    })], 2)] : _vm._e()];
  })], 2)], 1), _vm.showChildRoute ? _c("router-view") : _vm._e()], 1);
};
var staticRenderFns = [];
var ContainerFilling_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "ContainerFilling",
  mixins: [PermissionCheckFacility, PermissionCheckMixin],
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      collapsed: false,
      showChildRoute: false,
      permissions: {
        view: VIEW_PERMISSION
      },
      facilityOptions: [
        {
          code: "PC",
          name: "PC",
          entityLevelName: "NZ - Christchurch Frozens",
          entityLevelCode: "pc"
        },
        {
          code: "PH",
          name: "PH",
          entityLevelName: "NZ - Hastings Frozens",
          entityLevelCode: "ph"
        },
        {
          code: "PK",
          name: "PK",
          entityLevelName: "NZ - Hastings",
          entityLevelCode: "pk"
        }
      ],
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "Container Processing",
          path: "",
          key: "container-processing",
          icon: "form",
          child: [
            {
              title: "Container Production",
              path: "/container-filling/container-production",
              key: "container-production",
              page: CONTAINER_FILLING_CONTAINERPRODUCT
            },
            {
              title: "Manual Label",
              path: "/container-filling/manual-label",
              key: "manual-label",
              page: CONTAINER_FILLING_MANUALLABEL
            },
            {
              title: "Wedge Maintenance",
              path: "/container-filling/wedge-maintenance",
              key: "wedge-maintenance",
              page: CONTAINER_FILLING_WEDGEMAINTAIN,
              hide: this.facilityValue !== "PK"
            }
          ]
        },
        {
          title: "Reports",
          path: "/container-filling/reports",
          key: "reports",
          icon: "file-text",
          page: CONTAINER_FILLING_REPORTS,
          child: []
        },
        {
          title: "Sample Processing",
          path: "/container-filling/sample-processing",
          key: "sample-processing",
          icon: "file-protect",
          page: CONTAINER_FILLING_SAMPLEPROCESSING,
          child: []
        },
        {
          title: "Administration",
          path: "/container-filling/administration",
          key: "administration",
          icon: "cluster",
          page: CONTAINER_FILLING_ADMINISTRATION,
          child: []
        },
        {
          title: "Master Files",
          path: "",
          key: "masterfiles",
          icon: "tool",
          child: [
            {
              title: "Item",
              path: "/container-filling/item",
              key: "item",
              page: CONTAINER_FILLING_ITEM
            },
            {
              title: "Specification",
              path: "/container-filling/specification",
              key: "specification",
              page: CONTAINER_FILLING_SPECIFICATION
            },
            {
              title: "Production Line",
              path: "/container-filling/production-line",
              key: "production-line",
              page: CONTAINER_FILLING_PRODUCTIONLINE
            },
            {
              title: "Label Formats",
              path: "/container-filling/label-formats",
              key: "label-formats",
              page: CONTAINER_FILLING_LABELFORMAT
            },
            {
              title: "Printer",
              path: "/container-filling/label-printers",
              key: "label-printers",
              page: CONTAINER_FILLING_PRINTER
            },
            {
              title: "Status",
              path: "/container-filling/status",
              key: "status",
              page: CONTAINER_FILLING_STATUS
            },
            {
              title: "Container Type",
              path: "/container-filling/container-type",
              key: "container-type",
              page: CONTAINER_FILLING_CONTAINTERTYPE
            }
          ]
        }
      ],
      selectedKeys: [],
      openKeys: [],
      rootSubmenuKeys: [],
      facilityValue: ""
    };
  },
  metaInfo: {
    title: "Container Filling"
  },
  computed: {
    userInfo() {
      const { namespace, AUTH_GET_USER } = AuthTypes;
      return this.$store.getters[`${namespace}/${AUTH_GET_USER}`];
    }
  },
  watch: {
    userInfo(newVal) {
      this.facilityOptions = this.permissionFacility(APP_NAME.CF, this.facilityOptions, newVal);
    },
    facilityOptions(newVal) {
      let facilityCode = sessionStorage.getItem("cfFacility");
      if (facilityCode && newVal.findIndex((facility) => facility.code == facilityCode) > -1) {
        this.onFacilityChange(facilityCode);
        return;
      }
      this.onFacilityChange(newVal[0].code);
    }
  },
  created() {
    this.updateMenu();
    this.facilityOptions = this.permissionFacility(APP_NAME.CF, this.facilityOptions, this.userInfo);
  },
  methods: {
    filterFacilityOption(option) {
      return option.code != "AV";
    },
    getFacilityLabel(option) {
      return `Facility - ${option.name}`;
    },
    updateMenu() {
      if (!this.$route.matched[1])
        return;
      const path = this.$route.matched[1].path;
      this.openKeys = typeof this.$route.matched[1].meta == "string" ? [this.$route.matched[1].meta.split(";")[0]] : [];
      const title = path.substring(path.lastIndexOf("/") + 1);
      this.selectedKeys = [title];
    },
    clickItem(e) {
      this.selectedKeys = [e.key];
    },
    clickParentItem(e) {
      if (!this.openKeys.includes(e.key)) {
        this.openKeys = [...this.openKeys, e.key];
      } else {
        const keyIndex = this.openKeys.indexOf(e.key);
        if (keyIndex > -1)
          this.openKeys.splice(keyIndex, 1);
      }
    },
    async onFacilityChange(value) {
      this.showChildRoute = false;
      this.facilityValue = value;
      sessionStorage.setItem("cfFacility", value);
      try {
        await this.$context.setContainerFillingContext(value);
        this.dataMenu[1].child[2].hide = this.facilityValue !== "PK";
        if (this.facilityValue !== "PK" && this.$route.matched[1] && this.$route.matched[1].path === "/container-filling/wedge-maintenance") {
          this.$router.push("/container-filling/container-production");
          this.updateMenu();
        }
      } catch (err) {
        const message = this._.get(err, "response.data.message") || "Something went wrong, please try again";
        this.$notification.error({ message });
      } finally {
        this.showChildRoute = true;
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "a5f007e6", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var ContainerFilling = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { ContainerFilling as default };
